<template>
	<b-autocomplete
		placeholder="Müşteri/Tedarikçi seçiniz.."
		field="name"
		icon="search"
		size="is-small"
		v-model="value.name"
		:data="customers"
		:loading="$apollo.queries.readCustomer.loading"
		:disabled="disabled"
		@select="customers_select"
		@typing="customers_read"
		@focus="customers_focus"
		open-on-focus
		clearable
	>
		<template slot="empty">Sonuç bulunamadı.</template>

		<template slot="header">
			<a @click="customers_new">
				<span>Yeni Ekle</span>
			</a>
		</template>

		<template slot-scope="props">
			<div class="media">
				<div class="media-content">
					{{ props.option.class }}
					<br />
					<small>
						{{ props.option.name }} ~
						<b>{{ props.option.cusrep.name }}</b>
					</small>
				</div>
			</div>
		</template>
	</b-autocomplete>
</template>

<script>
import gql from "graphql-tag";

export default {
	name: "CustomerAutofill",
	props: ["value", "disabled"],
	data: () => ({
		customers: [],
	}),
	methods: {
		customers_focus() {
			this.customers_read();
		},
		customers_new() {
			this.$store.state.ui.customer_modal = true;
		},
		customers_read() {
			this.$apollo.queries.readCustomer.start();
		},
		customers_select(opt) {
			Object.assign(this.value, opt);
			this.$store.state.ui.flush++;
		},
	},
	apollo: {
		readCustomer: {
			query: gql`
				query readCustomers($config: String) {
					readCustomers(config: $config) {
						id
						class
						name
						firmrep
						phone
						cusrep {
							name
						}
					}
				}
			`,
			variables() {
				return {
					config: JSON.stringify({
						skip: 0,
						take: 5,
						sorts: [],
						filters: {
							name: this.value.name ? "%" + this.value.name + "%" : "%",
						},
					}),
				};
			},
			update(data) {
				this.customers = data.readCustomers;
			},
		},
	},
};
</script>
