<template>
	<b-autocomplete
		placeholder="Satış siparişi seçiniz.."
		field="name"
		icon="search"
		size="is-small"
		v-model="value.name"
		:data="saleorders"
		:loading="$apollo.queries.readSaleOrder.loading"
		:disabled="disabled"
		@select="saleorders_select"
		@typing="saleorders_read"
		clearable
		open-on-focus
	>
		<template slot="empty">Sonuç bulunamadı.</template>

		<template slot-scope="props">
			<div class="media">
				<div class="media-content">
					{{ props.option.id }}
					<br />
					<small>
						{{ props.option.name }} ~
						<b>{{
							new Date(props.option.issuedat).toLocaleDateString("tr-TR", {
								weekday: "long",
								year: "numeric",
								month: "numeric",
								day: "numeric",
							})
						}}</b>
					</small>
				</div>
			</div>
		</template>
	</b-autocomplete>
</template>

<script>
import gql from "graphql-tag";

export default {
	name: "SaleOrderAutofill",
	props: ["value", "disabled"],
	data: () => ({
		saleorders: [],
	}),
	methods: {
		saleorders_read() {
			this.$apollo.queries.readSaleOrder.start();
		},
		saleorders_select(opt) {
			Object.assign(this.value, opt);
		},
	},
	apollo: {
		readSaleOrder: {
			query: gql`
				query readSaleOrders($config: String) {
					readSaleOrders(config: $config) {
						id
						name
						issuedat
					}
				}
			`,
			variables() {
				return {
					config: JSON.stringify({
						skip: 0,
						take: 5,
						sorts: [],
						filters: {
							name: this.value.name ? "%" + this.value.name + "%" : "%",
						},
					}),
				};
			},
			update(data) {
				this.saleorders = data.readSaleOrders;
			},
		},
	},
};
</script>
