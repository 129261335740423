<template>
	<b-autocomplete
		placeholder="Parti no seçiniz.."
		field="lotnum"
		icon="search"
		size="is-small"
		v-model="text"
		:data="lots"
		:loading="$apollo.queries.readLotAutofill.loading"
		:disabled="disabled"
		@select="lots_select"
		@typing="lots_read"
		@focus="lots_focus"
		clearable
		open-on-focus
	>
		<template slot="empty">Sonuç bulunamadı.</template>

		<template slot-scope="props">
			<div class="media">
				<div class="media-left">
					<img width="32" src="@/assets/e.png" />
				</div>
				<div class="media-content">
					{{ props.option.material.code }}
					<br />
					<small>
						{{ props.option.lotnum }} ~
						<b>{{ props.option.quantity }} KG</b>
					</small>
				</div>
			</div>
		</template>
	</b-autocomplete>
</template>

<script>
import gql from "graphql-tag";

export default {
	name: "LotAutofill",
	props: ["value", "disabled", "id_material"],
	data: () => ({
		text: "",
		lots: [],
	}),
	created() {
		this.text = this.value;
	},
	methods: {
		lots_read() {
			this.$apollo.queries.readLotAutofill.start();
		},
		lots_select(opt) {
			this.$emit("value", opt.lotnum);
		},
		lots_focus() {
			this.lots_read();
		},
	},
	apollo: {
		readLotAutofill: {
			query: gql`
				query readLotAutofills($config: String) {
					readLotAutofills(config: $config) {
						id
						material {
							id
							code
							name
						}
						lotnum
						quantity
					}
				}
			`,
			variables() {
				return {
					config: JSON.stringify({
						skip: 0,
						take: 5,
						sorts: [],
						filters: {
							id_material: this.id_material,
							lotnum: this.text ? "%" + this.text + "%" : "%",
							quantity: 0,
						},
					}),
				};
			},
			update(data) {
				this.lots = data.readLotAutofills;
			},
		},
	},
};
</script>
